import React, { useState, Component, useEffect } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionFaqContent.module.css';
import Accordeon from '../Accordeon/Accordeon';

const SectionFaqContent = props => {
  const [ selectedBlock, setSelectedBlock ] =
    useState( props.categoryOption ? props.categoryOption.key : null );
  const { rootClassName, className, categoryOption, intl } = props;

  useEffect(()=>{
    setSelectedBlock(props.categoryOption ? props.categoryOption.key : null);
  },[props.categoryOption])
  const classes = classNames(rootClassName || css.root, className);

  const frequentlyAskedQuestions = config.custom.frequentlyAskedQuestions;
  const selectedBlockQuestions = frequentlyAskedQuestions[ selectedBlock ] ?
    frequentlyAskedQuestions[ selectedBlock ].questions : null;
  const questionsArr = Object.keys(selectedBlockQuestions).map(elem=>{
    return {label:selectedBlockQuestions[elem].question, content:selectedBlockQuestions[elem].answer}
  })
  const answersBlock = selectedBlockQuestions ? (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        <Accordeon radioName="FaqRadio" dataArr={questionsArr}/>
      </div>
    </div>
  ) : null;

  return answersBlock ? (
    <div className={classes}>
      <div className={css.faqTitle}>
        <FormattedMessage id={`SectionFaqContent.title_${categoryOption.key}`}/>
      </div>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          {answersBlock}
        </div>
      </div>
    </div>
  ) : null;
};

SectionFaqContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionFaqContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionFaqContent;
