import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, NamedLink } from '../../components';

import css from './SectionCategories.module.css';

import enterprenuershipImage1 from './images/business_coaching_1.jpg';
import enterprenuershipImage2 from './images/business_coaching_2.jpg';
import enterprenuershipImage3 from './images/business_coaching_3.jpg';
import ecommerceImage1 from './images/e_commerce_1.jpg';
import ecommerceImage2 from './images/e_commerce_2.jpg';
import ecommerceImage3 from './images/e_commerce_3.jpg';
import otherImage1 from './images/other_1.jpg';
import otherImage2 from './images/other_2.jpg';
import otherImage3 from './images/other_3.jpg';
import propertyImage1 from './images/property_1.jpg';
import propertyImage2 from './images/property_2.jpg';
import propertyImage3 from './images/property_3.jpg';
import tradingImage1 from './images/trading_1.jpg';
import tradingImage2 from './images/trading_2.jpg';
import tradingImage3 from './images/trading_3.jpg';

const CATEGORY_IMAGES = {
  business_coaching_1: enterprenuershipImage1,
  business_coaching_2: enterprenuershipImage2,
  business_coaching_3: enterprenuershipImage3,
  e_commerce_1: ecommerceImage1,
  e_commerce_2: ecommerceImage2,
  e_commerce_3: ecommerceImage3,
  other_1: otherImage1,
  other_2: otherImage2,
  other_3: otherImage3,
  property_1: propertyImage1,
  property_2: propertyImage2,
  property_3: propertyImage3,
  trading_1: tradingImage1,
  trading_2: tradingImage2,
  trading_3: tradingImage3,
};

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const emptyBlogBlock = categoryOption => (
  <NamedLink name="LandingPage" className={css.location}>
    <div className={css.imageWrapper}>
      <div className={css.aspectWrapper}>
        <LazyImage
          src={CATEGORY_IMAGES[`${categoryOption.key}_${categoryOption.entryIndex}`]}
          alt={categoryOption.label}
          className={css.locationImage}
        />
      </div>
    </div>
    <div className={css.linkText}>
      {categoryOption[`blog_title_${categoryOption.entryIndex}`]}
    </div>
  </NamedLink>
);

const blogBlock = categoryOption => (
  <ExternalLink
    key={categoryOption.key}
    href={categoryOption[`blog_link_${categoryOption.entryIndex}`]}
    className={css.location}
  >
    <div className={css.imageWrapper}>
      <div className={css.aspectWrapper}>
        <LazyImage
          src={CATEGORY_IMAGES[`${categoryOption.key}_${categoryOption.entryIndex}`]}
          alt={categoryOption.label}
          className={css.locationImage}
        />
      </div>
    </div>
    <div className={css.linkText}>
      {categoryOption[`blog_title_${categoryOption.entryIndex}`]}
    </div>
  </ExternalLink>
);

const SectionCategories = props => {
  const { rootClassName, className, categoryOption } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        { categoryOption.blog_link_1
            ? blogBlock({ ...categoryOption, entryIndex: 1 })
            : emptyBlogBlock({ ...categoryOption, entryIndex: 1 })}
        { categoryOption.blog_link_2
          ? blogBlock({ ...categoryOption, entryIndex: 2 })
          : emptyBlogBlock({ ...categoryOption, entryIndex: 2 })}
        { categoryOption.blog_link_3
          ? blogBlock({ ...categoryOption, entryIndex: 3 })
          : emptyBlogBlock({ ...categoryOption, entryIndex: 3 })}
      </div>
    </div>
  );
};

SectionCategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
