import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, NamedLink } from '../../components';

import css from './SearchResultsPanel.module.css';

import image1 from './images/image.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';
import image4 from './images/image4.png';
import { findOptionsForSelectFilter } from '../../util/search';

const CATEGORY_IMAGES = {
  business_coaching: image1,
  e_commerce: image2,
  property: image3,
  trading: image4,
};

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const CategoryBlock = categoryOption => {
  return (
    <NamedLink
      key={categoryOption.key}
      name="CategoryPage"
      params={{ category: categoryOption.key }}
      className={css.location}
    >
      <div className={css.selectionButton}>
        <img src={CATEGORY_IMAGES[categoryOption.key]} className={css.buttonIcon} />
        <p className={css.buttonText}>{categoryOption.label}</p>
      </div>
    </NamedLink>
  );
};

const SectionCategoryExplainer = props => {
  const { rootClassName, className, categoryOption } = props;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const categoryOptionKeys = ['business_coaching', 'e_commerce', 'property', 'trading'].filter(
    categoryKey => categoryOption.key !== categoryKey
  );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        {categoryOptionKeys.map(categoryKey =>
          CategoryBlock(categoryOptions.find(category => categoryKey === category.key))
        )}
      </div>
    </div>
  );
};

SectionCategoryExplainer.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategoryExplainer.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategoryExplainer;
