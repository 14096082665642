import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { NamedLink } from '../../components';
import { HeroSearchForm } from '../../forms';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, heroImageIndex = 1 } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css[`root1`], className);

  const handleSubmit = values => {
    const { currentSearchParams } = props;
    const { search, selectedPlace } = values.location;
    const { history } = props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      bounds,
    };

    history.push(createResourceLocatorString('CategoryPage', routeConfiguration(), { category: search }, {}));
  };

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage
            id="SectionHero.title"
          />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div
          id="heroSectionSearchBar"
          className={css.searchWrapper}
        >
          <HeroSearchForm
            className={css.searchLink}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={handleSubmit}
            initialValues={{ location: null }}
          />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
