/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    isListMenu = true,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const isMentor =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.role === 'mentor';

  if (isListMenu) {
    const mentorsSection = (
      <div className={css.section}>
        <div className={css.sectionHeading}>
          <FormattedMessage id="TopbarMobileMenu.browseMentors" />
        </div>
        <NamedLink name="CategoryPage" params={{ category: 'e_commerce' }} className={css.menuLink}>
          <FormattedMessage id="TopbarMobileMenu.eCommerce" />
        </NamedLink>
        <NamedLink name="CategoryPage" params={{ category: 'trading' }} className={css.menuLink}>
          <FormattedMessage id="TopbarMobileMenu.trading" />
        </NamedLink>
        <NamedLink name="CategoryPage" params={{ category: 'property' }} className={css.menuLink}>
          <FormattedMessage id="TopbarMobileMenu.realEstate" />
        </NamedLink>
        <NamedLink
          name="CategoryPage"
          params={{ category: 'business_coaching' }}
          className={css.menuLink}
        >
          <FormattedMessage id="TopbarMobileMenu.enterprenuership" />
        </NamedLink>
      </div>
    );

    const userSection = (
      <div className={css.section}>
        <div className={css.sectionHeading}>
          <FormattedMessage id="TopbarMobileMenu.myAccount" />
        </div>
        <NamedLink className={css.menuLink} name="InboxPage" params={{ tab: 'orders' }}>
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
        </NamedLink>
        {isMentor ? (
          <NamedLink className={css.menuLink} name="ManageListingsPage">
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : null}
        <NamedLink className={css.menuLink} name="ProfileSettingsPage">
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink className={css.menuLink} name="AccountSettingsPage">
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink name="SignupPage" className={css.menuLink}>
          <FormattedMessage id="TopbarMobileMenu.applyToBeAMentor" />
        </NamedLink>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.signOutLink" />
        </InlineTextButton>
      </div>
    );

    const loginSection = (
      <div className={css.section}>
        <div className={css.sectionHeading}>
          <FormattedMessage id="TopbarMobileMenu.browseMentors" />
        </div>
        <NamedLink name="SignupPage" className={css.menuLink}>
          <FormattedMessage id="TopbarMobileMenu.signupLink" />
        </NamedLink>
        <NamedLink name="LoginPage" className={css.menuLink}>
          <FormattedMessage id="TopbarMobileMenu.loginLink" />
        </NamedLink>
      </div>
    );

    return (
      <div className={css.root}>
        <div className={css.contentList}>
          {mentorsSection}
          {isAuthenticated ? userSection : loginSection}
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />

      <div className={css.greetingContainer}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.signOutLink" />
        </InlineTextButton>
      </div>
      <div className={css.content}>
        <NamedLink className={css.menuLink} name="InboxPage" params={{ tab: 'orders' }}>
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
        </NamedLink>
        <NamedLink className={css.menuLink} name="InboxPage" params={{ tab: 'orders' }}>
          <FormattedMessage id="TopbarMobileMenu.ordersLink" />
        </NamedLink>
        {currentUserHasListings && (
          <NamedLink className={css.menuLink} name="InboxPage" params={{ tab: 'sales' }}>
            <FormattedMessage id="TopbarMobileMenu.salesLink" />
          </NamedLink>
        )}
        <NamedLink className={css.menuLink} name="ManageListingsPage">
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink className={css.menuLink} name="ProfileSettingsPage">
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink className={css.menuLink} name="AccountSettingsPage">
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  isListMenu: bool,
};

export default TopbarMobileMenu;
