import React from 'react';

import css from './CoursePaper.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FormattedMessage } from '../../util/reactIntl';

import star from './star.png';

const CoursePaper = ({ image, link, title, ranking, rankingAmount, mentorName, price, unit = "ListingCard.perUnit" }) => {
  const history = useHistory();
  const onClick = () => history.replace(link);
  return (
    <div onClick={onClick} className={css.root}>
      <img src={image} className={css.mainImage} />
      <div className={css.rankingContainer}>
        {rankingAmount > 0 && (
          <>
            <img src={star} className={css.rankingStar} />
            <p className={css.ranking}>{ranking}</p>
            <p className={css.rankingAmount}>{`(${rankingAmount})`}</p>
          </>
        )}
      </div>
      <p className={css.title}>{title}</p>
      <p className={css.mentorName}>
        <FormattedMessage values={{ name: mentorName }} id="ListingPage.hostedBy" />
      </p>
      <p className={css.priceStatement}>
        <b className={css.price}>{price}</b> <FormattedMessage id={unit}></FormattedMessage>
      </p>
    </div>
  );
};

export default CoursePaper;
