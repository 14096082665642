import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaLinkedin,
  IconSocialMediaTiktok,
  IconSocialMediaTwitter,
  FooterLogo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteLinkedinPage,
    siteTiktokPage,
    siteTwitterHandle
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedin' });
  const goToTiktok = intl.formatMessage({ id: 'Footer.goToTiktok' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteFacebookPage ? (
    <ExternalLink key="linkToTiktok" href={siteTiktokPage} className={css.icon} title={goToTiktok}>
      <IconSocialMediaTiktok />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink key="linkToLinkedin" href={siteLinkedinPage} className={css.icon} title={goToLinkedin}>
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  return [ fbLink, twitterLink, instragramLink, tiktokLink, linkedinLink ].filter(v => v != null);
};

const FooterComponent = props => {
  const { rootClassName, className, intl, currentUser } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const faqPage = (
    <li className={css.listItem}>
      <NamedLink name="ContactPage" className={css.link}>
        <FormattedMessage id="Footer.toFAQPage" />
      </NamedLink>
    </li>
  );

  const blogPage = (
    <li className={css.listItem}>
      <ExternalLink key="linkToBlogPage" href="https://mentorme.io/blog/" className={css.link} title="Blog">
        <FormattedMessage id="Footer.toBlogPage" />
      </ExternalLink>
    </li>
  );

  const companyInfo = (
    <div className={css.companyInfo}>
      <FormattedMessage id="Footer.companyInfo" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <FooterLogo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                { !currentUser ? (
                  <li className={css.listItem}>
                    <NamedLink name="SignupPage" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  </li>
                ) : null }
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                {blogPage}
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toHelpPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="CategoryPage"
                    params={{ category: 'e_commerce'}}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.eCommerce" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="CategoryPage"
                    params={{ category: 'trading'}}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.trading" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="CategoryPage"
                    params={{ category: 'property'}}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.property" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="CategoryPage"
                    params={{ category: 'business_coaching'}}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.enterprenuership" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <a
                    href='https://mentorme-sidehustle.scoreapp.com/'
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.quiz" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a
                    href='https://www.skool.com/hustlehub-5684'
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.community" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.link}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.link}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
            </div>
          </div>
          {companyInfo}
        </div>
      </div>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return { currentUser };
};

const Footer = compose(
  connect( mapStateToProps )
)(injectIntl(FooterComponent));

export default Footer;
