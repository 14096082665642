import React from "react";

import css from "./LinkButton.module.css"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LinkButton = ({text, link}) => {
    const history = useHistory()
    const onClick = () => {
        history.push(link)
    }
    return (
        <div onClick={onClick} className={css.linkButton}>{text}</div>
    )
}

export default LinkButton