import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const AccountIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootAccountIcon, className);

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="url(#pattern0_103_330)" />
      <defs>
        <pattern id="pattern0_103_330" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use href="#image0_103_330" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_103_330"
          width="100"
          height="100"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEI0lEQVR4nO2dXYhVVRTHt5NZBKEigvUyESMzXGf///tyxIeKLkVEEgYK06Mfr74JIho+GA1kJDRjEAQ9FfSQZUn4VPii7+EHEURQPviJ86I4SuWOTUeQGY84es5Z68xeP/i/DDN3WOt31r3nnrvPvs4ZhmEYhmEYxhDJDQD2kvyW5BmSMyRvl5kpf3ak/J0Nzrkha1vNFEXxHIBJkudJxoUEwF/pb9NjmJjHxHu/kuQ0ydmFirhPZsvHWmFiHgEAW0herkHE3FxKj21SHpKJiYknyiM5Npyp9L9MzAMYHh5+GsDRFmTcfX05mv6nSamejCNtybgnxwaDwVKTMoeWnqZixaR8YkLmv4BHwdwhudmk/P8eYzmAC8JCYjr7SqfZ2UsheViBjFhmOmshvV5vDYCbCkTEMrP9fv95lyvl5ZCoKQAmXaYMpetMCoX8meUFyXQlVrr5rEi/31/vcgPAPunGszp7XG6Un2dINz5WPG1943KD5FnpxrM6Z1xukLymeEKuutwoP3KNSnPL5YaCpscHxeWGdMNpQkyIaqQngDYhJkQ10hNAmxATohrpCaBNiAlRjfQE0CbEhKhGegJoEzJPyC3pprMiaeGFy42GVrbHmnLR5UZ5p1NUmtMuN0h+rfgp6yuXG2khgWIhu11ukAzSjWd11rkMWaJxoRzJP1yukDyg8Olqn8uVdKuyssUOsyGE1S5nlN2O8KHLnbGxsVXlDgxROBdHR0efle6HCki+Kyzjjvf+Hek+qILkF4JCDknXr45er7cMwPG2ZQD43jYQqKAoimdI/tyikGPpQGj30OsYg8FgaRv3rAP43DYMWABsWEhzh9QihSZEFzQhuqAJ0QVNiC5oQnRBE6ILmhBd0ITowXu/sYXLJi9L16mekZGRp8qPddtY1TgDYKt0zSopiuJJkjsA/N6CiLnXtH4KIbwk3QMVpC1aQwg705ZIbYvgfDEnSL6d69ZMK9JiNCV7LcY5YtKU7spiS3Lv/Wh5af26dOP5cHvFfzk+Pg632ADwCskfy+1YpRsdH2FqTgHY5LpOCOGNVIx0Q1lfTgJ43XWNEMLatAmYggbGBs/Meq4LZ00kP1a2GjE2lNsAPkrvnZzWF2wAvyhoVGx5Ws4BGHeaILmd5A3p5lAu10MI25wClmhcwU65aTmYeiJmA8Cn0k2gvkyJyCC5X0HxUWO89++1KgPAWyT/lS6cepN682Zrtw8AuKKg6Kg87XwfCcnPFBQbO5LDjcrw3r8I4B8FhcYuBMDfJF9oTEg6g5Aukt3LoSZXpavdHpyKty1v5P4Tkq9JF8eOxnv/au1CAHwgXRi7m/ebEPKDgsJiR/Nd7UJI/qagsNjFAPi1diH2ZpCPI+VyVlvyUX/q/z4SBUXFLseEcJELMQzDMAzDMAzDcPXwH/egsZxiUjMCAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

AccountIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

AccountIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default AccountIcon;
