import React from 'react';
import PropTypes from 'prop-types';

import config from '../../config';
import LogoImage from './mentor-me-logo.png';

const FooterLogo = props => {
  const { className, format, ...rest } = props;

  return <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />;
};

const { oneOf, string } = PropTypes;

FooterLogo.defaultProps = {
  className: null,
  format: 'desktop',
};

FooterLogo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default FooterLogo;
