import React from 'react';

import css from './SectionNotSure.module.css';
import LinkButton from '../LinkButton/LinkButton';
import imageSrc from './image.png';

const SectionNotSure = () => {
  return (
    <div className={css.root}>
      <div className={css.generalWrapper}>
        <div className={css.contentContainer}>
          <p className={css.title}>Not sure which mentor to choose?</p>
          <p className={css.content}>
            Let us help you find the perfect match! Book a free pairing call with our experts, and
            we’ll guide you to the mentor best suited to your needs and goals. We’ll help you
            navigate your options, provide personalized recommendations, and start your journey with
            confidence.
          </p>
          <LinkButton text="Get matched now" link="." />
        </div>
        <img className={css.sectionImage} src={imageSrc} />
      </div>
    </div>
  );
};

export default SectionNotSure;
