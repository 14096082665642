import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { createSlug } from '../../util/urlHelpers';
import { ExternalLink } from '../../components';

import css from './SectionRelatedBlogs.module.css';

import blogPostImage1 from './images/blog_post1.jpg';
import blogPostImage2 from './images/blog_post2.jpg';
import blogPostImage3 from './images/blog_post3.jpg';

class BlogPostImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(BlogPostImage);

const locationLink = (title, link, image) => {
  const titleText = <span className={css.blogPostTitle}>{title}</span>;

  return (
    <ExternalLink key={createSlug(title)} href={link} className={css.blogPost}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={title} className={css.blogPostImage} />
        </div>
      </div>
      <div className={css.linkText}>{titleText}</div>
    </ExternalLink>
  );
};

const SectionRelatedBlogs = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <div className={css.title}>Blogs related to Property</div>
        <div className={css.subtitle}>
          Stay updated with the latest tips, insights, and success stories from our mentors and
          community. Learn more about Property and achieving your goals.
        </div>
      </div>

      <div className={css.recomTop}>
        <div className={css.recomTitle}>
          <FormattedMessage id="SectionExplore.recommendationsTitle" />
        </div>
        <ExternalLink key="blogPosts" href="https://mentorme.io/blog/" className={css.seeAll}>
          <FormattedMessage id="SectionExplore.actionButton" />
        </ExternalLink>
      </div>

      <div className={css.blogPosts}>
        {locationLink(
          intl.formatMessage({ id: 'SectionExplore.blogPostTitle1' }),
          intl.formatMessage({ id: 'SectionExplore.blogPostLink1' }),
          blogPostImage1
        )}
        {locationLink(
          intl.formatMessage({ id: 'SectionExplore.blogPostTitle2' }),
          intl.formatMessage({ id: 'SectionExplore.blogPostLink2' }),
          blogPostImage2
        )}
        {locationLink(
          intl.formatMessage({ id: 'SectionExplore.blogPostTitle3' }),
          intl.formatMessage({ id: 'SectionExplore.blogPostLink3' }),
          blogPostImage3
        )}
      </div>
    </div>
  );
};

SectionRelatedBlogs.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionRelatedBlogs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionRelatedBlogs;
