import React from 'react';

import { NamedLink, ListingCard } from '../../components';

import css from './SectioMentorSuggestions.module.css';

const SectionMentorSuggestions = props => {

  const {
    title,
    description,
    category,
    listings,
    currentListingId
  } = props

  if (!listings?.length) return null

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const listingsData = listings.filter(l => l.id.uuid !== currentListingId)?.slice(0,4);

  return (
    <div className={css.root}>
      <div className={css.textContainer}>
        <p className={css.title}>{title}</p>
      </div>
      <div className={css.recommendationsContainer}>
        <div className={css.recomTop}>
          <p className={css.recomTitle}>{description}</p>
          <NamedLink
            name="CategoryPage"
            params={{ category }}
            className={css.seeAll}
          >
            See all {'>'}
          </NamedLink>
        </div>
        <div className={css.recomPapersContainer}>
          {listingsData.map(l => (
            <ListingCard
              className={css.recomPapers}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={() => { }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionMentorSuggestions;
