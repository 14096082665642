/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        //{ key: 'fulfilment_by_amazon', label: 'Fulfilment by Amazon' },
        {
          key: 'e_commerce',
          label: 'E-Commerce',
          description: 'What is E-Commerce? Well it\'s the future of shopping! It\'s the buying and selling of products or services over the internet. Our expert mentors will show you how to make it simple and fun with Shopify, Amazon FBA, Dropshipping, or Personal Shopping. Contact a mentor to start your journey and discover the world of E-Commerce today!',
          blog_title_1: 'Where are Amazon Fulfilment Centres in the UK?',
          blog_link_1: 'https://mentorme.io/blog/amazon-fulfilment-centres-uk/',
          blog_title_2: 'Is E-commerce Legit?',
          blog_link_2: 'https://mentorme.io/blog/is-e-commerce-legit/',
          blog_title_3: 'Best Website Platforms for E-commerce',
          blog_link_3: 'https://mentorme.io/blog/best-website-platforms-for-e-commerce/',
        },
        {
          key: 'trading',
          label: 'Trading & Investing',
          description: 'Step into the world of wealth-building! Trading & Investing is all about buying and selling financial assets such as Stocks, Crypto, Forex, and Derivatives with the aim of growing your wealth. Our expert mentors are here to show you the ropes and make this journey exciting and fun. Learn the fundamentals and psychological aspects of trading financial instruments. Contact a mentor to start your journey and discover the world of trading & investing today!',
          blog_title_1: 'Starting a Trading Career',
          blog_link_1: 'https://mentorme.io/blog/starting-a-trading-career/',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
        {
          key: 'property',
          label: 'Property',
          description: 'Invest in property, the future of wealth! Property refers to real estate, buildings, and land. Our expert mentors will show you the different strategies like HMOs, R2R, SA, BRRRR and Flips. Learn how to make property work for you and build long-term wealth. Contact a mentor to start your journey and discover the world of property today!',
          blog_title_1: 'Property Buying & Sales',
          blog_link_1: 'https://mentorme.io/blog/property-buying-and-sales/',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
        {
          key: 'business_coaching',
          label: 'Entrepreneurship',
          description: 'Unlock your business potential with a coach! A business coach is a guide who helps entrepreneurs and business owners achieve their goals through leadership coaching, mindset work, life coaching, and social media strategies. Our expert mentors are here to help you unlock your full potential and take your business to new heights. Join us and let\'s turn your vision into reality with the help of a knowledgeable business coach. Contact a mentor to start or continue your journey and to business success today!',
          blog_title_1: 'How to find a mentor for your career',
          blog_link_1: 'https://mentorme.io/blog/how-to-find-a-mentor/',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
        {
          key: 'other',
          label: 'Other',
          description: '',
          blog_title_1: 'Coming soon...',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Expertise',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 25000,
      step: 5,
    },
  },
  {
    id: 'listing_type',
    label: 'Listing type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        { key: 'profile', label: 'Hourly' },
        { key: 'course', label: 'Packages' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'pub_averageRating', label: 'Highest rating' },
    { key: '-pub_averageRating', label: 'Lowest rating' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const frequentlyAskedQuestions = {
  e_commerce: {
    label: "E-Commerce",
    questions: {
      question_e_commerce_1: {
        question: "What experience do E-Commerce Mentors have?",
        answer: "Our E-Commerce mentors have years of experience in running online stores, optimizing Shopify and WooCommerce platforms, scaling Amazon FBA businesses, and growing dropshipping stores. Each mentor has a variety of skills, from building a store to sourcing profitable items."
      },
      question_e_commerce_2: {
        question: "Can an E-Commerce Mentor help me start a Shopify store?",
        answer: "Yes! Our Dropshipping mentors will guide you through every step, from setting up your store to optimizing it for conversions and integrating the right tools and apps."
      },
      question_e_commerce_3: {
        question: "How do I find the right E-Commerce niche with a mentor?",
        answer: "An E-Commerce mentor can help you conduct market research, identify profitable niches, and choose the best products to sell, ensuring your business idea aligns with current trends and consumer demands."
      },
      question_e_commerce_4: {
        question: "Will a mentor teach me how to increase traffic to my E-Commerce website?",
        answer: "Yes, E-Commerce mentors focus on strategies like SEO, content marketing, email marketing, and paid ads (Google, Facebook, Instagram) to drive traffic and increase sales."
      },
      question_e_commerce_5: {
        question: "Can a mentor help with scaling my Amazon FBA business?",
        answer: "Definitely! Mentors specializing in Amazon FBA can guide you on inventory management, keyword optimization, sourcing products, and running PPC campaigns to grow your sales and expand globally, whether it's private label, wholesale, or bundles."
      },
      question_e_commerce_6: {
        question: "How can I get started?",
        answer: "Request to book a session with your chosen mentor. Once accepted, the mentor will set up a time with you to get you onboarded and ready to go!"
      }
    }
  },

  property: {
    label: "property",
    questions: {
      question_real_estate_1: {
        question: "How can a property mentor help me start a business in Property?",
        answer: "Our property mentors can teach you strategies like Flips, HMOs, Rent to Rent, Buy-To-Let, and Commercial Real Estate Investments."
      },
      question_real_estate_2: {
        question: "Can a property mentor guide me on buy-to-let investments?",
        answer: "Yes, buy-to-let specialists can teach you how to identify lucrative rental properties, secure financing, and manage tenants to maximize your rental income."
      },
      question_real_estate_3: {
        question: "What advice do mentors give on financing property investments?",
        answer: "Property mentors help you explore financing options such as mortgages, bridging loans, and private funding. They can also provide guidance on improving your credit score and securing the best deals."
      },
      question_real_estate_4: {
        question: "Will a property mentor help me with house flipping?",
        answer: "Absolutely. Our mentors can guide you on purchasing properties below market value, renovating them effectively, and selling for profit, all while managing time and budget efficiently."
      },
      question_real_estate_5: {
        question: "Can a mentor help with property management?",
        answer: "Yes, property mentors can guide you on managing rental properties, including tenant screening, lease agreements, and dealing with maintenance and repairs, all aimed at maximizing your rental yield."
      },
      question_real_estate_6: {
        question: "How can I get started?",
        answer: "Request to book a session with your chosen mentor. Once accepted, the mentor will set up a time with you to get you onboarded and ready to go!"
      }
    }
  },

  trading: {
    label: "Trading & Investing",
    questions: {
      question_trading_1: {
        question: "What types of trading and investing do mentors specialize in?",
        answer: "Our mentors cover various types of trading, including stock trading, forex, cryptocurrency, options, and day trading, as well as long-term investing strategies in stocks, real estate, and alternative assets."
      },
      question_trading_2: {
        question: "Can a mentor help me understand cryptocurrency investing?",
        answer: "Yes, our crypto trading mentors can help you understand blockchain technology, analyze market trends, and create a strategy for investing in Bitcoin, Ethereum, and other altcoins."
      },
      question_trading_3: {
        question: "Do mentors teach risk management strategies for trading?",
        answer: "Absolutely. Our trading mentors emphasize risk management strategies, including setting stop-loss orders, diversifying your portfolio, and using leverage responsibly."
      },
      question_trading_4: {
        question: "How can a mentor help with stock market investing?",
        answer: "Mentors provide strategies & tips on stock market fundamentals, technical analysis, and long-term investment strategies."
      },
      question_trading_5: {
        question: "Will a trading mentor teach me how to develop a trading plan?",
        answer: "Yes, a trading mentor can guide you in building a personalized trading plan based on your risk tolerance, financial goals, and preferred market (stocks, forex, crypto)."
      },
      question_trading_6: {
        question: "How can I get started?",
        answer: "Request to book a session with your chosen mentor. Once accepted, the mentor will set up a time with you to get you onboarded and ready to go!"
      }
    }
  },

  business_coaching: {
    label: "Entrepreneurship",
    questions: {
      question_business_coaching_1: {
        question: "How can an entrepreneurship mentor help me grow my business?",
        answer: "Entrepreneurship mentors guide you through scaling your business, identifying growth opportunities, refining your business model, and optimizing operations to boost profitability."
      },
      question_business_coaching_2: {
        question: "Can a mentor help me with startup fundraising?",
        answer: "Yes, our mentors specialize in helping entrepreneurs secure funding through venture capital, angel investors, crowdfunding, and grants. They also assist with pitch deck creation and investor relations."
      },
      question_business_coaching_3: {
        question: "How do mentors help with developing a business plan?",
        answer: "Our mentors work with you to create a solid business plan, outlining your value proposition, target market, revenue model, and marketing strategy, which is crucial for both growth and securing funding."
      },
      question_business_coaching_4: {
        question: "Will a mentor assist with marketing strategies for my startup?",
        answer: "Yes, entrepreneurship mentors and business coaches can help you craft effective marketing strategies, including digital marketing, social media, content marketing, and lead generation techniques tailored to your target audience."
      },
      question_business_coaching_5: {
        question: "Can a mentor help me overcome challenges in scaling my business?",
        answer: "Absolutely. Whether it's optimizing processes, hiring the right team, or breaking through revenue plateaus, our mentors provide tailored advice to help you overcome challenges and continue scaling your business."
      },
      question_business_coaching_6: {
        question: "How can I get started?",
        answer: "Request to book a session with your chosen mentor. Once accepted, the mentor will set up a time with you to get you onboarded and ready to go!"
      }
    }
  }
};
